<template>
    <ServidorInfoDisplay
        :espaco_total="metricas.espaco_total"
        :espaco_livre="metricas.espaco_livre"
        :espaco_usado="metricas.espaco_usado"
        :percentual_uso="metricas.percentual_uso"
    ></ServidorInfoDisplay>
</template>

<script>
    import {get} from "../../../../src/helpers/apiRequest.js";
    import ServidorInfoDisplay from "./ServidorInfoDisplay";

    export default{
        name: "InformacoesServidor",
        components: {
           ServidorInfoDisplay,
        },
        data(){
            return {
                metricas: {
                    'espaco_total': null,
                    'espaco_livre': null,
                    'espaco_usado': null,
                    'percentual_uso': null,
                },
            };
        },
        created() {
            this.metricas.espaco_total = this.$route.params.espaco_total;
            this.metricas.espaco_livre = this.$route.params.espaco_livre;
            this.metricas.percentual_uso = this.$route.params.percentual_uso;
            this.metricas.espaco_usado = this.$route.params.espaco_usado;
            this.loadInfo(this.espaco_total, this.espaco_livre, this.espaco_usado, this.percentual_uso);
        },
        methods: {
            loadInfo(){
                get('/admin/info-servidor')
                .then((json) => {
                    this.metricas = json;
                })
                
                .catch((err) => {
                });
            },
        }
    }
    

</script>

