<template>
    <div>
        <crud-base
            :url="url"
            :columns="columns"
            :onShow="() => {}"
            :enableSearch="true"
        />
    </div>
</template>

<script>
  import CrudBase from "../../../components/crud/CrudBase";

  export default {
    name: "LogsRequisicoes",
    components: {
      CrudBase
    },
    data() {
      return {
        url: '/admin/logs/requisicoes',
        columns: [
          {label: 'ID', field: 'id', sortable: false},
          {label: 'Url', field: 'url', sortable: false},
          {label: 'Método', field: 'metodo', sortable: false},
          {label: 'Data/Hora', field: 'datahora_acesso', sortable: false},
          {label: 'Duração', field: 'duracao', sortable: false},
        ],
      }
    },
  }
</script>