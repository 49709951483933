<template>
  <div class="server-info">
    <h2 class="sub-title">Percentual Usado:</h2>
    <div class="livre-usado"
         :style="{alignItems: 'center'}">
      <div
          class="bar"
          :style="{width: calculateUsado() + '%', backgroundColor: validateAviso().color}"
      >
                <span :style="{display: displayUsado() ? 'none': 'inline'}">
                    {{ calculateUsado().toFixed(2) }}%
                </span>
      </div>
      <span :style="{display: displayUsado() ? 'inline': 'none', paddingLeft: '10px', color: '#004377'}">
                    {{ calculateUsado().toFixed(2) }}%
                </span>
    </div>

    <h2 class="sub-title"> Espaço em Disco: </h2>

    <div
        class="bar total"
        :style="{width: 100 + '%'}"
    >
      {{ espaco_total }}GB TOTAL
    </div>

    <div class="livre-usado">
      <div
          class="bar livre"
          :style="{width: calculateLivre() + '%'}"
      >
                <span :style="{display: displayLivre() ? 'none': 'inline' }">
                    {{ espaco_livre }}GB LIVRE
                </span>
      </div>

      <div
          class="bar usado"
          :style="{width: calculateUsado() + '%'}"
      >
                <span :style="{display: displayUsado() ? 'none': 'inline' }">
                    {{ espaco_usado }}GB USADO
                </span>
      </div>
    </div>
    <div
        class="aviso-display"
        :style="{ color: validateAviso().color}"
    >
      STATUS: {{validateAviso().text}}
    </div>
  </div>
</template>

<script>
export default {
  name: "ServidorInfoDisplay",
  props: {
    espaco_total: Number,
    espaco_livre: Number,
    espaco_usado: Number,
    percentual_uso: Number,
  },
  methods: {
    calculateLivre() {
      return (this.espaco_livre / this.espaco_total) * 100
    },
    calculateUsado() {
      return (this.espaco_usado / this.espaco_total) * 100
    },
    displayLivre() {
      return this.calculateLivre() <= 15;
    },
    displayUsado() {
      return this.calculateUsado() <= 15;
    },
    validateAviso() {
      const percentualMinAlto = 85
      const percentualMinUrgente = 95
      const percentualAtual = this.calculateUsado()

      let statusUso = {
        'color': '',
        'text': ''
      }

      if (percentualAtual < percentualMinAlto) {
        statusUso.color = '#82b647'
        statusUso.text = 'NORMAL'
      } else if (percentualAtual >= percentualMinAlto && percentualAtual < percentualMinUrgente) {
        statusUso.color = '#f5822b'
        statusUso.text = 'ALTO'
      } else if (percentualAtual >= percentualMinUrgente) {
        statusUso.color = '#E61E50'
        statusUso.text = 'EXTREMO'
      }

      return statusUso;
    }
  },
};
</script>

<style scoped>
.server-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 180px;
}

.sub-title {
  margin-top: 25px;
  font-weight: 400;
  font-size: 14px;
  color: #3c4b64;
}

.bar {
  font-size: 14px;
  padding-top: 8px;
  margin-bottom: 5px;
  text-align: center;
  border-radius: 5px;
  min-height: 35px;
  color: #ffffff;

}

.usado-percentual {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 15px;
}

.aviso-display {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}

.livre-usado {
  display: flex;
}

.livre {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #82aecc;
}

.usado {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #6695ac;
}

.total {
  background-color: #54aab8;

}
</style>