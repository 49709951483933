<template>
    <div>
        <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab title="Informações do Servidor">
              <InformacoesServidor />
            </CTab>
            <CTab title="Requisições">
                <LogsRequisicoes />
            </CTab>
            
        </CTabs>
    </div>
</template>

<script>
  import LogsRequisicoes from "./requisicoes/LogsRequisicoes";
  import InformacoesServidor from "./InformacoesDeServidor/InformacoesServidor";

  export default {
    name: "Logs",
    components: {
      LogsRequisicoes,
      InformacoesServidor
    },
  }
</script>